import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
// import Antd from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.css'
// import FileSaver from 'file-saver'
// import JSZip from 'jszip'

// Vue.use(Antd)
Vue.use(ElementUI)
Vue.use(VueCodemirror)
// Vue.use(FileSaver)
// Vue.use(JSZip)


new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
