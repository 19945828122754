const state = () => ({
  dbName: "localData",
  tableNames: ["table"],
  tableDatas: {
    table: [
      {
        name: "id",
        epithet:"ID",
        typ: "INTEGER PRIMARY KEY AUTOINCREMENT",
        searchTyp: "equal",
        search: false,
        only: true,
        new: false
      },
      {
        name: "name",
        epithet:"名称",
        typ: "TEXT",
        searchTyp: "none",
        search: false,
        only: false,
        new: false
      },
    ],
  },
  lockData: true,
});
const getters = {
  tableData: (state) => {
    return {
      dbName: state.dbName,
      tableNames: state.tableNames,
      tableDatas: state.tableDatas,
      lockData: state.lockData,
    };
  },
};
const mutations = {
  setDbName(state, value) {
    state.dbName = value;
  },
  setTableNames(state, value) {
    // state.tableNames = JSON.parse(JSON.stringify(value));
    state.tableNames = value;
  },
  setTableDatas(state, value) {
    // state.tableDatas = JSON.parse(JSON.stringify(value));
    state.tableDatas = value;
  },
  setLockData(state, value) {
    state.lockData = value;
  },
  cleanForm(state) {
    state.dbName = "localData";
    state.tableNames = ["table"];
    state.tableDatas = {
      table: [
        {
          name: "id",
          epithet:"ID",
          typ: "INTEGER PRIMARY KEY AUTOINCREMENT",
          searchTyp: "equal",
          search: false,
          only: true,
          new: false
        },
        {
          name: "name",
          epithet:"名称",
          typ: "TEXT",
          searchTyp: "none",
          search: false,
          only: false,
          new: false
        },
      ],
    };
    state.lockData = true;
  },
};
const actions = {
  setDbName({ commit }, value) {
    commit("setDbName", value);
  },
  setTableNames({ commit }, value) {
    commit("setTableNames", value);
  },
  setTableDatas({ commit }, value) {
    commit("setTableDatas", value);
  },
  setLockData({ commit }, value) {
    commit("setLockData", value);
  },
  cleanForm({ commit }) {
    commit("cleanForm");
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
