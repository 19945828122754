const state = () => ({
    leftCol: 12
  });
  const getters = {
    pageData: (state) => {
      return {
        leftCol: state.leftCol
      };
    },
  };
  const mutations = {
    setLeftCol(state, value) {
      state.leftCol = value;
    },
    resetData(state) {
      state.leftCol = 12;
    },
  };
  const actions = {
    setLeftCol({ commit }, value) {
      commit("setLeftCol", value);
    },
    resetData({ commit }) {
      commit("resetData");
    },
  };
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
  