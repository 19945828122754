import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const HomeView = () => import(/* webpackChunkName: "home" */ '../views/page/home.vue')
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
]

const router = new VueRouter({
  routes
})

export default router

